import { graphql } from "gatsby"
import React from "react"
import { HarviaBackgroundColor } from "../../layouts/BgColor"
import CtaToExternalUrlBlock from "../../molecules/tileable-blocks/CtaToExternalUrlBlock"
import { MediaTextBlockLayout } from "../../molecules/tileable-blocks/MediaTextBlock"

const DatoCtaToExternalUrl = (props: Queries.DatoCtaToExternalUrlFragment) => {
  //console.log("Rendering DatoCtaToExternalUrl", props)
  const { textBackground, layout, text, title, linkText, image, url, ...rest } = props

  if (!url) {
    return null
  }

  return (
    <CtaToExternalUrlBlock
      {...rest}
      backgroundColor={(textBackground || "BrandWhite") as HarviaBackgroundColor}
      text={text || ""}
      title={title || ""}
      linkText={linkText || ""}
      image={image || undefined}
      url={url}
      layout={(layout || "Tile") as MediaTextBlockLayout}
    />
  )
}

export default DatoCtaToExternalUrl

export const query = graphql`
  fragment DatoCtaToExternalUrl on DatoCmsCtaToExternalUrl {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    text
    title
    linkText
    image {
      ...DatoImageDefault
    }
    url
    textBackground
    layout
  }
`
